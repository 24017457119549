import es6Promise from "es6-promise";
import "webcrypto-shim";
import "fast-text-encoding";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.typed-array.slice";

es6Promise.polyfill();

const onClientEntry = () => {
  // needs to have this to imitate call
};

export { onClientEntry };
